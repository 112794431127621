import React from "react";
import { withStyles } from "@material-ui/core/styles";
import "./Error.css";
import { organizationsId } from "../../lib/organizations";
import LevelOneButton from "../LevelOneButton";

const styles = {
  grid: {
    height: "700px",
  },
  button: {
    background: `linear-gradient(to right, #feb940, #f4085f)`,
    float: "center",
    width: "12em",
    color: "white",
    textAlign: "center",
    margin: "10px",
    fontSize: "14px",
    "&:hover": {
      boxShadow: "0 0 10px #f4085f",
    },
    borderRadius: "20px",
  },
  potenzaButton: {
    background: "#045858",
    "&:hover": {
      boxShadow: "none",
      filter: "brightness(1.4)",
      backgroundColor: "#045858 !important",
      transition: "ease 300ms",
    },
  },
  title: {
    marginTop: "5%",
    color: "#feb940",
  },
};

const illustration = (
  <svg
    className="alert"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 52"
    color="error"
  >
    <circle className="alert__circle" cx="26" cy="26" r="25" fill="none" />
    <line className="alert__line" x1="26" y1="10" x2="26" y2="32"></line>
    <circle className="alert__point" cx="26" cy="40" r="1" fill="none" />
  </svg>
);

function Error(props) {
  const { classes, handleRetry, redirect, organization, paymentError } = props;
  return (
    <div
      className={classes.card}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
		alignItems: 'center',
      }}
    >
      <div style={{ textAlign: "center", fontSize: '24px' }}>
        Su pago no se ha podido realizar
      </div>
      {illustration}

      {redirect === false && (
        <>
          <div style={{ textAlign: "center" }}>
            {paymentError || "Intente nuevamente"}
          </div>
          <LevelOneButton
            onClick={() => handleRetry(true)}
            disabled={false}
            className={
              organization.id === organizationsId.potenza
                ? classes.potenzaButton
                : undefined
            }
          >
            {"Reintentar"}
          </LevelOneButton>
        </>
      )}
    </div>
  );
}

export default withStyles(styles)(Error);
