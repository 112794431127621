/** Nota: Copiado del repo pagos360/checkout-v3 */
const emailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/** Devuelve una string con un mensaje de error del email si es inválido.
 *
 * Si el email es válido, devuelve null.
 */
export function getEmailError(email) {
  if (!email.trim()) {
    return "Este campo es requerido";
  }

  if (email.length < 7) {
    return "Mínimo 7 caracteres";
  }

  if (email.length > 200) {
    return "Máximo 200 caracteres";
  }

  if (!emailRegExp.test(email)) {
    return "El e-mail no es válido";
  }

  // Valid email:
  return null;
}
